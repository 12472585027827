import React from "react";

function DiscountRulesTourES(props: any) {
    return (
        <>
            <p className="my-2">
                En todos los casos, <b>se requiere documentación de respaldo.</b>
            </p>
            {
                props.productCode === process.env.REACT_APP_BILHETE_BONDINHO
                    ?
                    <div>
                        <h5>ENTRADA PROMO BANDINHO BRASIL</h5>
                        <p>Los descuentos se otorgan a los brasileños nacidos o residentes, previa presentación individual de los siguientes documentos originales (o copia certificada), digitales o impresos.</p>
                        <ul>
                            <li>
                                <span>Residentes Adultos:</span>
                                <p>
                                    Comprobante de domicilio a tu nombre (agua, gas, luz, celular, internet, tarjeta o teléfono) y identificación oficial con foto (digital o impresa).
                                </p>
                            </li>
                            <li>
                                <span>Adultos Nacidos:</span>
                                <p>
                                    Documento de identificación oficial con foto, (digital o impreso).
                                </p>
                            </li>
                        </ul>
                    </div>
                    :
                    ""
            }
            <div>
                <h5>ENTRADA LIBRE</h5>
                <ul>
                    <li>
                        <span>ESCALADORES</span>
                        <p>
                            Descenso libre para los afiliados a FEMERJ, mediante presentación de acreditación.
                        </p>
                    </li>
                    <li>
                        <span>NIÑOS HASTA 2 AÑOS</span>
                        <p>
                            Están exentos del pago al presentar el certificado de nacimiento
                            original (o copia) o identificación oficial con foto (o copia
                            certificada).
                        </p>
                    </li>
                </ul>
            </div>
            <div>
                <h5>MEDIA ENTRADA</h5>
                <ul>
                    <li>
                        <span>NIÑOS DE 3 A 12 AÑOS</span>
                        <p>
                            Presentación del certificado de nacimiento original (o copia) o
                            identificación oficial con foto (o copia certificada).
                        </p>
                    </li>
                    <li>
                        <span>JÓVENES DE 13 A 21 AÑOS</span>
                        <p>Identificación oficial con foto (o copia certificada).</p>
                    </li>
                    <li>
                        <span>
                            ID JOVEN - JÓVENES CON EDAD ENTRE 15 Y 29 AÑOS INSCRITOS EN EL
                            CATASTRO ÚNICO PARA PROGRAMAS SOCIALES DEL GOBIERNO FEDERAL
                        </span>
                        <p>
                            Presentación de la ID Joven, acompañada de la identificación
                            oficial con foto (o copia certificada).
                        </p>
                    </li>
                    <li>
                        <span>ESTUDIANTES</span>
                        <p>
                            Enseñanza Básica, Enseñanza Media, Graduación, Posgrado, MBA,
                            Maestrías y Doctorados): original o copia certificada de la Carnet
                            Nacional de Identificación Estudiantil (CNIE), dentro del plazo de
                            validez o:
                        </p>
                        <ul>
                            <li>
                                <p>
                                    Red Pública: presentación de la identificación oficial con
                                    foto (o copia certificada), acompañado de: RioCard escolar o
                                    identificación que compruebe la matrícula de estudiante,
                                    dentro del plazo de validez.
                                </p>
                            </li>
                            <li>
                                <p>
                                    Red Privada: presentación de la identificación oficial con
                                    foto (o copia certificada), acompañado de: Billete de pago a
                                    la institución del mes actual impreso o identificación que
                                    comprueba la matrícula de estudiante, dentro del plazo de
                                    validez.
                                </p>
                            </li>
                            <li>
                                <p>
                                    Estudiantes Extranjeros: presentar una cartera de
                                    identificación estudiantil con foto.
                                </p>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <span>+ 60 AÑOS</span>
                        <p>
                            A partir de 60 años con identificación oficial con foto (o copia
                            certificada).
                        </p>
                    </li>
                    <li>
                        <span>PCD Y SU COMPAÑERO</span>
                        <p>
                            Presentación de la identificación oficial con foto (o copia
                            certificada), acompañado de una Tarjeta Especial, RioCard, Vale
                            Social, Pass Federal, CRAS o certificado médico impreso (original
                            o copia certificada) emitido por la agencia de la salud pública.
                            El descuento está limitado a 1 acompañante por PCD (Personas con
                            Deficiencia).
                        </p>
                    </li>
                    <li>
                        <span>
                            PROFESORES Y PROFESIONALES DE LA ENSEÑANZA - RED PÚBLICA MUNICIPAL
                            DE RIO DE JANEIRO, SEGÚN LA LEY MUNICIPAL N° 3.424/02, DE RIO DE
                            JANEIRO
                        </span>
                        <p>
                            Presentación de la identificación oficial con foto (o copia
                            certificada) y Tarjeta Funcional emitida por el Departamento
                            Municipal de Educación o el sueldo que se identifique la entidad o
                            establecimiento educativo empleador y la función que ocupa.
                        </p>
                    </li>
                    <li>
                        <span>
                            GARIS DE LA COMPAÑÍA DE LIMPIEZA MUNICIPAL (COMLURB), DEL MUNICIPIO DE RIO DE JANEIRO, SEGÚN LEY MUNICIPAL Nº 8.251/2024, DE RIO DE JANEIRO.
                        </span>
                        <p>
                            Presentación de documento de identificación oficial con fotografía (o copia certificada) y Tarjeta Funcional o Gafete expedido por el organismo oficial.
                        </p>
                    </li>
                </ul>
            </div>
        </>
    );
}

export default DiscountRulesTourES;
