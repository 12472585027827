import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";

import "./DropdownHours.scss";
import { Col, Row } from "react-bootstrap";
import api from "../../services/api";
import { useTranslation } from "react-i18next";
import monitoringAPI from "../../services/monitoring";

export interface propsR {
    changePriceDateTour: any,
    dateTour: any,
    index?: any,
    setPeopleDisponible?: any,
    edit?: any,
    item?: any,
    info?: any,
    isIntegration?: any,
    isDetailsProduct?: any,
    setApiError?: any,
    setHoursRange?: any
}

type Request = {
    page: number,
    rowsPerPage: number,
    dateStart: string,
    tarUniqueCode: string,
    prodModUniqueCode: string,
    daysUniqueTarCode: string,
    idCanal: number
}
type IntegrationRequest = {
    "dateStart": string,
    "productCode": string,
    "ProdModCode": string,
}

const DropdownHours: React.FC<propsR> = ({
    changePriceDateTour, dateTour, index = 0, setPeopleDisponible, edit = false, item, info, isIntegration = false, isDetailsProduct = false, setApiError = () => {}, setHoursRange = false
}: propsR) => {
    const url = window.location.href;
    const codeUrl = url.split("/");
    const isTrilha = codeUrl[3] === "compra-rapida" ? codeUrl[4]?.split("?")[2] : codeUrl[4]?.split("?")[1];

    const { t } = useTranslation();

    const [isOpen, setIsOpen] = useState<any>(false);
    const [hours, setHours] = useState<any>(null);
    const [hoursParam, setHoursParam] = useState<any>([]);
    const [select, setSelect] = useState<any>(null);
    const [selectNext, setSelectNext] = useState<any>(null);
    const [loading, setLoading] = useState<any>(true);

    /* Detecta clique fora da div#wrapper para fechar*/
    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef);

    function useOutsideAlerter(ref: any) {
        useEffect(() => {
            function handleClickOutside(event: any) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setIsOpen(false);
                }
            }
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }

    const handleClickHours = () => {
        isOpen === false ? setIsOpen(true) : setIsOpen(false);
    };

    function handleSelectHours(hour: any, price: any, hours: any, i: any = 0) {
        setSelect(hour);

        let horaFinalAux: any = dateTour[index]?.daysUniqueTarCode === 'TKTDT-FB679581' ? { hora: '18:30:00' } : { hora: '19:00:00' };

        const nextItem: any =
            hours[i + 1] === undefined ?
                (dateTour[index]?.daysUniqueTarCode === 'TKTDT-FB679581' || dateTour[index]?.daysUniqueTarCode === 'TKTDT-NC132372' ? horaFinalAux : hours[i]) :
                hours[i + 1]

        setSelectNext(nextItem?.hora/* hours[i + 1]?.hora === undefined ? hour : hours[i + 1]?.hora */);

        if (!isDetailsProduct) {
            setHoursRange(timeShow(transformTime(hour), transformTime(nextItem?.hora/* hours[i + 1]?.hora === undefined ? hour : hours[i + 1]?.hora */)));
        }

        var aux: any = dateTour;
        aux[index].time = hour;
        if (isIntegration === false) {
            aux[index].adult = price.sellingTarifAdultFinal;
            aux[index].child = price.sellingTarifChildFinal;
            aux[index].infant = price.sellingTarifInfantFinal;
            aux[index].student = price.sellingTarifStudentFinal;
            aux[index].elders = price.sellingTarifEldersFinal;
            aux[index].global = price.sellingTarifGlobalFinal;
            aux[index].maxStockNumber = info.productCode === process.env.REACT_APP_SUPPLIER_BEACH ? price?.totalAmount : price?.balanceAvailableByChannel;
            aux[index].hours = hours;
        } else {
            aux[index].maxStockNumber = price.capacity;
            aux[index].hours = hours;
        }

        setIsOpen(false);
        if (edit !== true) {
            setPeopleDisponible(true);
        }
        changePriceDateTour(aux, hour);
    }

    function transformTime(time: string) {
        const hour: number = +time?.split(":")[0];
        const min: number = +time?.split(":")[1];

        return `${hour}h${min > 0 ? min : ""}`;
    }

    function timeShow(actual: string, next: string) {
        if (info?.productCode !== 'PRD-ZC954218' && info?.productCode !== 'PRD-GC992492') {

            if (next === actual) {
                return `${actual} às ${`${(Number(next?.split("h")[0]) + 1) > 23 ? 0 : (Number(next?.split("h")[0]) + 1)}h${Number(next?.split("h")[1]) > 0 ? Number(next?.split("h")[1]) : ""}`}`;
            } else {
                return `${actual} às ${next}`;
            }
        } else {
            return `${actual}`
        }
    }

    async function listHours(idChannel: number = 2) {
        const token = localStorage.getItem('GroupId') || '{}';
        const config = {
            headers: { 'Authorization': `Bearer ${token}` }, "ngrok-skip-browser-warning": "69420"
        };

        let date: any = new Date();
        let dateActual: any = date.toLocaleString().replaceAll(",", "").split(" ")[0].split("/").reverse().join("");

        let req: Request = {
            "page": 1,
            "rowsPerPage": 10,
            "dateStart": dateTour[index].data,
            "tarUniqueCode": dateTour[index].tarUniqueCode,
            "prodModUniqueCode": dateTour[index].prodModUniqueCode/* 'MOA5357' */,
            "daysUniqueTarCode": dateTour[index].daysUniqueTarCode,
            "idCanal": isTrilha === "trilha" ? 7 : idChannel
        }

        try {
            const res: any = await api.post(`api/Products/GetTicketAndTourHoursStockAsync`, req, config);
            if (res.status === 200) {
                if (res.data.statusCode === 200) {
                    monitoringAPI(200, 'Products', 'GetTicketAndTourHoursStockAsync', res?.data?.data?.texto || 'Sucesso', '', '/api/Products/GetTicketAndTourHoursStockAsync', req);
                    setLoading(false);

                    if (dateActual === dateTour[index].data.replaceAll("-", "")) {
                        setHoursParam(res.data.data);
                    } else {
                        setHours(res.data.data);

                        if (res.data.data.length === 1) {
                            handleSelectHours(res.data.data[0].hora, res.data.data[0], res.data.data);
                        }
                    }
                } else {
                    //ERRO STATUS CODE 400
                    setApiError((prev: any) => { return ([...prev, { index, text: t('modalApiError.errorAPI'), errorOnCalendar: false }]) });
                    monitoringAPI(400, 'Products', 'GetTicketAndTourHoursStockAsync', res?.data?.data?.texto || 'Retornado status diferente de 200', '', '/api/Products/GetTicketAndTourHoursStockAsync', req);
                }
            } else {
                //ERRO STATUS 400
                setApiError((prev: any) => { return ([...prev, { index, text: t('modalApiError.errorAPI'), errorOnCalendar: false }]) });
                monitoringAPI(400, 'Products', 'GetTicketAndTourHoursStockAsync', res?.data?.data?.texto || 'Retornado status diferente de 200', '', '/api/Products/GetTicketAndTourHoursStockAsync', req);
            }
        } catch (error: any) {
            //ERRO
            setApiError((prev: any) => { return ([...prev, { index, text: t('modalApiError.errorAPI'), errorOnCalendar: false }]) });
            monitoringAPI(400, 'Products', 'GetTicketAndTourHoursStockAsync', error?.response?.data?.errorMessage || 'Retornado status diferente de 200', '', '/api/Products/GetTicketAndTourHoursStockAsync', req);
        }
    }

    async function listHoursIntegration() {
        console.log("ASDHJBIJHBASD")
        const token = localStorage.getItem('GroupId') || '{}';
        const config = {
            headers: { 'Authorization': `Bearer ${token}` }, "ngrok-skip-browser-warning": "69420"
        };

        let req: IntegrationRequest = {
            "dateStart": dateTour[index].data,
            "productCode": info.productCode,
            "ProdModCode": info.modalities[index].prodModCode,
        }

        try {
            const res: any = await api.post(`api/Products/GetTicketActivitiesIntegrationAsync`, req, config);
            if (res.status === 200) {
                if (res.data.statusCode === 200) {
                    monitoringAPI(200, 'Products', 'GetTicketActivitiesIntegrationAsync', res?.data?.data?.texto || 'Sucesso', '', '/api/Products/GetTicketActivitiesIntegrationAsync', req);
                    setLoading(false);
                    setHours(res.data.data);

                    if (res.data.data.length === 1 && res.data.data[0].startTime === null) {
                        handleSelectHours("00:00:00", res.data.data[0], res.data.data);
                    }
                } else {
                    //ERRO STATUS CODE 400
                    setApiError((prev: any) => { return ([...prev, { index, text: t('modalApiError.errorAPI') }]) });
                    monitoringAPI(400, 'Products', 'GetTicketActivitiesIntegrationAsync', res?.data?.data?.texto || 'Retornado status diferente de 200', '', '/api/Products/GetTicketActivitiesIntegrationAsync', req);
                }
            } else {
                //ERRO STATUS 400
                setApiError((prev: any) => { return ([...prev, { index, text: t('modalApiError.errorAPI') }]) });
                monitoringAPI(400, 'Products', 'GetTicketActivitiesIntegrationAsync', res?.data?.data?.texto || 'Retornado status diferente de 200', '', '/api/Products/GetTicketActivitiesIntegrationAsync', req);
            }
        } catch (error: any) {
            //ERRO
            setApiError((prev: any) => { return ([...prev, { index, text: t('modalApiError.errorAPI') }]) });
            monitoringAPI(400, 'Products', 'GetTicketActivitiesIntegrationAsync', error?.response?.data?.errorMessage || 'Retornado status diferente de 200', '', '/api/Products/GetTicketActivitiesIntegrationAsync', req);
        }
    }

    async function getIdChannel() {
        const config2 = {
            headers: { "ngrok-skip-browser-warning": "69420" },
        };
        try {
            const { data } = await api.get(`${process.env.REACT_APP_SERVER_URL_API}/api/Products/GetChannelBySource/site`, config2);
            if (data.statusCode === 200) {
                monitoringAPI(200, 'Products', 'GetChannelBySource', data?.data?.texto || 'Sucesso', '', '/api/Products/GetChannelBySource/site', '');
                listHours(data.data.data);
            } else {
                monitoringAPI(400, 'Products', 'GetChannelBySource', data?.data?.texto || 'Retornado status diferente de 200', '', '/api/Products/GetChannelBySource/site', '');
                listHours();
            }
        } catch (error: any) {
            monitoringAPI(400, 'Products', 'GetChannelBySource', error?.response?.data?.errorMessage || 'Retornado status diferente de 200', '', '/api/Products/GetChannelBySource/site', '');
            listHours();
        }
    }

    useEffect(() => {
        if (edit !== true) {
            setSelect(null);
            setPeopleDisponible(false);
        } else {
            if (dateTour[0].time === undefined) {
                dateTour[0].time = item.startTimeLocal.split("T")[1];
            }
        }

        setLoading(true);

        if (isIntegration === false) {
            getIdChannel();
        } else {
            listHoursIntegration();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dateTour[index]?.data]);

    useEffect(() => {
        let auxHours: any = [];
        let date: any = new Date();
        let timeActual: any = date.toLocaleString().replaceAll(",", "").split(" ")[1].replaceAll(":", "");
        let aux: any = 10000 //1 hora

        if (hoursParam?.length > 0) {
            for (let i = 0; i < hoursParam?.length; i++) {
                aux = dateTour[index]?.daysUniqueTarCode === 'TKTDT-FB679581' && i === (hoursParam?.length - 1) ? 13000 : 10000;

                if (hoursParam[i + 1]?.hora.replaceAll(":", "") !== undefined) {
                    aux = hoursParam[i + 1]?.hora.replaceAll(":", "") - hoursParam[i]?.hora.replaceAll(":", "");
                }

                if ((Number(hoursParam[i]?.hora.replaceAll(":", "")) + aux) > timeActual) {
                    auxHours.push(hoursParam[i]);
                }
            }
            setHours(auxHours);

            //caso só exista um horário, já seleciona automático
            if (auxHours.length === 1) {
                handleSelectHours(auxHours[0].hora, auxHours[0], auxHours);
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hoursParam])

    useEffect(() => {
        if (edit === true) {
            setSelect(item?.startTimeLocal.split("T")[1]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (/* dateTour[index]?.hours? */hours?.length !== 1 || info.reservationSystem === 99 || info.productCode === process.env.REACT_APP_SUPPLIER_BEACH) { // Produto SIG x SIG aparecer o horário
        if (isOpen === true && isIntegration === false) {
            return (
                <div className={isDetailsProduct === true ? "panel-dropdown bg-white bg-option-hours" : ""}>
                    <div className="mb-5 bg-hours">
                        <div style={{ borderBottom: "1px solid #707070" }} className="bg-border-hours">
                            <p style={{ fontSize: "18px", color: "#707070" }}><strong>{t("slowBuy.ticket.passo03")} </strong> {t("slowBuy.ticket.selectHours")}</p>

                            <div style={{ padding: "0.5rem 0 0.375rem 0", height: "42px" }} className="bg-height-hours">
                                <div id="wrapper" ref={wrapperRef}>
                                    <div className="d-flex align-items-center" style={{ cursor: "pointer", color: "#666" }} onClick={() => handleClickHours()}>
                                        <FontAwesomeIcon
                                            icon={["fal", "clock"]}
                                            className="size-icon-hours mr-2"
                                        />
                                        <span style={{ marginLeft: "40px", fontSize: "18px" }}>
                                            {select === null ? `${t("slowBuy.ticket.chooseHours")}` : timeShow(transformTime(select), transformTime(selectNext))}
                                        </span>
                                    </div>

                                    <div className="panel-dropdown-content panel-hours right active">
                                        <h6 className="hours-title">
                                            {hours?.length > 0 ? `${t("slowBuy.ticket.disponibleHours")}` : `${t("slowBuy.ticket.noDisponibleHours")}`}
                                        </h6>
                                        <Row className="mx-0">
                                            {hours?.map((x: any, xIndex: any) => {
                                                const disabled: boolean = x?.balanceAvailableByChannel === 0;
                                                let horaFinalAux: any = dateTour[index]?.daysUniqueTarCode === 'TKTDT-FB679581' ? { hora: '18:30:00' } : { hora: '19:00:00' };
                                                const nextItem: any =
                                                    hours[xIndex + 1] === undefined ?
                                                        (dateTour[index]?.daysUniqueTarCode === 'TKTDT-FB679581' || dateTour[index]?.daysUniqueTarCode === 'TKTDT-NC132372' ? horaFinalAux : hours[xIndex]) :
                                                        hours[xIndex + 1];

                                                return (
                                                    <>
                                                        <Col
                                                            xs={4}
                                                            lg={isDetailsProduct ? 4 : 3}
                                                            key={xIndex}
                                                            data-capacity={x?.balanceAvailableByChannel}
                                                            className="p-1"
                                                        >
                                                            <div className="hours-options" onClick={() => { handleSelectHours(x.hora, x, hours, xIndex) }} aria-disabled={disabled}>
                                                                <div>
                                                                    <p>{`${timeShow(transformTime(x.hora), transformTime(nextItem?.hora))}`}</p>
                                                                    {x?.seeSpots && <><small style={{ fontSize: ".75rem" }}>{x?.sellingTarifAdultFinal.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</small><br /></>}
                                                                    {
                                                                        disabled
                                                                            ?
                                                                            <>
                                                                                <small style={{ fontSize: ".75rem" }}>{t("hours.soldOut")}</small>
                                                                            </>
                                                                            :
                                                                            /* x?.balanceAvailableByChannel <= x?.lastetSpots
                                                                                ?
                                                                                <>
                                                                                    <small style={{ fontSize: ".75rem" }}>{t("hours.lastSpots")}</small>
                                                                                </>
                                                                                : */
                                                                                ""
                                                                    }
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </>
                                                )
                                            }
                                            )}
                                        </Row>
                                        {/* <p id="alertNumber"></p> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        } else if (isOpen === true && isIntegration === true && (hours?.length > 0 && hours[0]?.startTime !== null)) {
            return (
                <div className={isDetailsProduct === true ? "panel-dropdown bg-white bg-option-hours" : ""}>
                    <div className="mb-5 bg-hours">
                        <div style={{ borderBottom: "1px solid #707070" }} className="bg-border-hours">
                            <p style={{ fontSize: "18px", color: "#707070" }}><strong>{t("slowBuy.ticket.passo03")} </strong> {t("slowBuy.ticket.selectHours")}</p>

                            <div style={{ padding: "0.5rem 0 0.375rem 0", height: "42px" }} className="bg-height-hours">
                                <div id="wrapper" ref={wrapperRef}>
                                    <div className="d-flex align-items-center" style={{ cursor: "pointer", color: "#666" }} onClick={() => handleClickHours()}>
                                        <FontAwesomeIcon
                                            icon={["fal", "clock"]}
                                            className="size-icon-hours mr-2"
                                        />
                                        <span style={{ marginLeft: "40px", fontSize: "18px" }}>
                                            {select === null ? `${t("slowBuy.ticket.chooseHours")}` : timeShow(transformTime(select), transformTime(selectNext))}
                                        </span>
                                    </div>

                                    <div className="panel-dropdown-content panel-hours right active">
                                        <h6 className="hours-title">
                                            {hours?.length > 0 ? `${t("slowBuy.ticket.disponibleHours")}` : `${t("slowBuy.ticket.noDisponibleHours")}`}
                                        </h6>
                                        <Row className="mx-0">
                                            {hours?.map((x: any, index: any) =>
                                                <>
                                                    <Col
                                                        xs={4}
                                                        lg={3}
                                                        key={index}
                                                        data-capacity={x?.capacity}
                                                        className="p-1"
                                                    >
                                                        <div className="hours-options" onClick={() => handleSelectHours(x?.startTime, x, hours)}>
                                                            <p>{`${x?.startTime.split(":")[0]}:${x?.startTime.split(":")[1]}`}</p>
                                                            <small>{x?.capacity} {t("hours.spots")}</small>
                                                        </div>
                                                    </Col>
                                                </>
                                            )}
                                        </Row>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        } else if (hours?.length <= 0 && isIntegration === true) {
            dateTour[index].maxStockNumber = 99990;
            setPeopleDisponible(true);
            return (
                <></>
            )
        } else {
            if (loading !== true) {
                return (
                    <div className={isDetailsProduct === true ? "panel-dropdown bg-white bg-option-hours" : ""}>
                        <div className="mb-5 bg-hours">
                            <div style={{ borderBottom: "1px solid #707070" }} className="bg-border-hours">
                                <p style={{ fontSize: "18px", color: "#707070" }}><strong>{t("slowBuy.ticket.passo03")} </strong> {t("slowBuy.ticket.selectHours")}</p>

                                <div style={{ padding: "0.5rem 0 0.375rem 0", height: "42px" }} className="bg-height-hours">
                                    <div className="d-flex align-items-center" style={{ cursor: "pointer", color: "#666" }} onClick={() => handleClickHours()}>
                                        <FontAwesomeIcon
                                            icon={["fal", "clock"]}
                                            className="size-icon-hours mr-2"
                                            style={{ fontSize: "22px" }}
                                        />
                                        <span style={{ marginLeft: "40px", fontSize: "18px" }}>{select === null ? `${t("slowBuy.ticket.chooseHours")}` : timeShow(transformTime(select), transformTime(selectNext))}</span>
                                        <span
                                            className="qtyTotal"
                                            data-min="2"
                                            data-max="19"
                                        >
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            } else {
                return (
                    <div className={isDetailsProduct === true ? "panel-dropdown bg-white bg-option-hours" : ""}>
                        <div className="mb-5 bg-hours">
                            <div style={{ borderBottom: "1px solid #707070" }} className="bg-border-hours">
                                <p style={{ fontSize: "18px", color: "#707070" }}><strong>{t("slowBuy.ticket.passo03")} </strong> {t("slowBuy.ticket.selectHours")}</p>

                                <div style={{ padding: "0.5rem 0 0.375rem 0", height: "42px" }} className="bg-height-hours"></div>
                                <div className="d-flex align-items-center justify-content-start" style={{ cursor: "pointer", color: "#666", position: "relative" }} onClick={() => handleClickHours()}>
                                    <div className="load" style={{ position: "absolute", bottom: "10px" }}></div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            }
        }
    } else {
        if (loading === true) {
            return (
                <div className={isDetailsProduct === true ? "panel-dropdown bg-white bg-option-hours" : ""}>
                    <div className="mb-5 bg-hours">
                        <div style={{ borderBottom: "1px solid #707070" }} className="bg-border-hours">
                            <p style={{ fontSize: "18px", color: "#707070" }}><strong>{t("slowBuy.ticket.passo03")} </strong> {t("slowBuy.ticket.selectHours")}</p>

                            <div style={{ padding: "0.5rem 0 0.375rem 0", height: "42px" }} className="bg-height-hours"></div>
                            <div className="d-flex align-items-center justify-content-start" style={{ cursor: "pointer", color: "#666", position: "relative" }} onClick={() => handleClickHours()}>
                                <div className="load" style={{ position: "absolute", bottom: "10px" }}></div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <>
                </>
            )
        }
    }
}

export default DropdownHours;