import React, { useState, useEffect } from "react";
//import i18next from "i18next";

import { Container, Row, Col, Card } from "react-bootstrap";

import { useTranslation } from "react-i18next";

import imgStore from "../../assets/img/img-default.jpg";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "./StoresList.scss";

export interface propStore {
    stores: any;
    filterLocation: any;
    filterType: any;
    pagination: any;
    setPagination: any;
}

const StoresList: React.FC<propStore> = ({
    stores, filterLocation, filterType, pagination, setPagination
}: propStore) => {
    const [t] = useTranslation();

    const [tourListSize, setTourListSize] = useState<any>(null);


    const paginateFoward = () => {
        setPagination(pagination + 5);
    }

    const paginateBack = () => {
        setPagination(pagination - 5);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    let aux: any = [];

    useEffect(() => {
        let initial: any = [];

        if (stores?.length > 0) {
            if (filterLocation.length > 0) {
                for (let i = 0; i < stores.length; i++) {
                    if (filterLocation.includes(stores[i].location)) {
                        initial.push(stores[i]);
                    }
                }
            } else {
                initial = stores;
            }

            if (filterType.length > 0) {
                for (let i = 0; i < initial.length; i++) {
                    if (filterType.includes(initial[i].type)) {
                        aux.push(initial[i].type);
                    }
                }
            } else {
                aux = initial;
            }

            setTourListSize(aux.length === 0 ? 0 : aux.length)
        } else {
            setTourListSize(0)
        }
    }, [aux])

    if (stores !== null) {
        return (
            <Container fluid className="store-list">
                {stores.length > 0
                    ? stores.filter((store: any) => filterLocation.length === 0 && filterType.length === 0 ?
                        store :
                        filterLocation.length > 0 && filterType.length > 0 ?
                            (filterLocation.includes(store.location) && filterType.includes(store.type)) :
                            filterLocation.length === 0 ?
                                filterType.includes(store.type) :
                                filterLocation.includes(store.location)
                    ).slice(pagination - 5, pagination).map((store: any, index: any) => {
                        return (
                            <div key={index}>
                                <Card className="my-4">
                                    <Row>
                                        <Col sm={4} lg={3} className="col-5 px-0 px-xl-3">
                                            {store.image === '' ?
                                                <div className="img-store" style={{ backgroundImage: `url(${imgStore})` }}></div>
                                                :
                                                <div className="img-store" style={{ backgroundImage: `url(${store.image})` }}></div>
                                            }
                                        </Col>
                                        <Col sm={8} lg={9} className="col-7">
                                            <Row>
                                                <Col lg={4}>
                                                    <h5><b>{store.name}</b></h5>
                                                    <span>{store.type}</span>
                                                </Col>
                                                <Col lg={4}>
                                                    {
                                                        store.location === "" || store.location === undefined
                                                            ?
                                                            ""
                                                            :
                                                            <p>{store.location}</p>
                                                    }
                                                    {
                                                        store.location2 === "" || store.location2 === undefined
                                                            ?
                                                            ""
                                                            :
                                                            <p>{store.location2}</p>
                                                    }
                                                </Col>
                                                <Col lg={4}>
                                                    <div>
                                                        {
                                                            store.phone === "" || store.phone === undefined
                                                                ?
                                                                ""
                                                                :
                                                                <p>{t("store.storeList.phone")} {(store.phone.replace("55 ", "")).replace("21", "(21)")}</p>
                                                        }
                                                        {
                                                            store.phone2 === "" || store.phone2 === undefined
                                                                ?
                                                                ""
                                                                :
                                                                <p>{t("store.storeList.phone")} {(store.phone2.replace("55 ", "")).replace("21", "(21)")}</p>
                                                        }
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Card>
                            </div>
                        )
                    }) : ""}
                <div className="d-flex justify-content-end align-items-center mb-3 mb-md-0">
                    {pagination / 5 > 1 ? <button className="paginate-btn" onClick={paginateBack}>{
                        <FontAwesomeIcon
                            icon={["fas", "chevron-left"]}
                            size="1x"
                            style={{ color: "#FFF", fontSize: "16px" }}
                        />
                    }</button> : ''}
                    <span>{t("store.storeList.page")} {pagination / 5} - {t("store.storeList.show")} ({pagination - 5} - {pagination}) de {tourListSize}</span>
                    {(pagination / 5 < (tourListSize / 5)) ? <button className="paginate-btn" onClick={paginateFoward}>{
                        <FontAwesomeIcon
                            icon={["fas", "chevron-right"]}
                            size="1x"
                            style={{ color: "#FFF", fontSize: "16px" }}
                        />
                    }</button> : ''}
                </div>
            </Container>
        )
    } else {
        return (
            <>
                <h2>{t("store.storeList.any")}</h2>
            </>
        )
    }
}

export default StoresList;