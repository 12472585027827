import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import api from '../../services/api';

import Form from 'react-bootstrap/Form';

export interface propPlus {
  propsField: any,
  propsErrors: any,
  uf: any,
  defaultValue?: any,
  setValue?: any,
  setValueString?: any
};

const GetCitysByState: React.FC<propPlus> = ({
  propsField, propsErrors, uf, defaultValue = null, setValue, setValueString = 'city'
}: propPlus) => {

  const [info, setInfo] = useState<any>(null);

  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

  const onMenuOpen = () => {
    if (isMenuOpen === false) {
      setIsMenuOpen(true);
    } else {
      setIsMenuOpen(false);
    }
  }

  useEffect(() => {
    const token = localStorage.getItem('GroupId') || '{}';
    const config = {
      headers: { 'Authorization': `Bearer ${token}` },
    };
    async function listCity() {
      try {
        const { data } = await api.get(`/api/DropDown/GetCitysByState/${uf}`, config);
        if (data.status !== 400) {
          setInfo(data.data);
          
          if (defaultValue && data.data.find((elem: any) => { return (elem.description === defaultValue) })) {
            let aux: any = data.data.find((elem: any) => { return (elem.description === defaultValue) })
            setValue(setValueString, { id: aux.id, description: aux.description })
          }
        }
      } catch (error: any) {
        if (error.response.status === 401) {
          window.location.href = window.location.origin + '/';
        }
      }
    }

    if (uf !== undefined) {
      listCity();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uf]);

  /* useEffect(() => {
    if (info !== null) {
      setValue(setValueString, info.find((elem: any) => elem.description === defaultValue));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [info]) */

  if (info !== null) {
    return (
      <>
        <div className={propsErrors.city !== undefined ? "endpoint-error" : ""}>
          <Select
            {...propsField}
            aria-labelledby="aria-label"
            inputId="aria-example-input"
            aria-invalid={propsErrors?.city ? "true" : ""}
            name="aria-live-color"
            onMenuOpen={onMenuOpen}
            onMenuClose={onMenuOpen}
            as="select"
            variant="standard"
            margin="normal"
            placeholder={'Cidade'}
            options={Array.from(info)}
            getOptionLabel={(option: any) => `${option.description}`}
          />
        </div>
      </>
    );
  } else {
    return (
      <>
        <Form.Select>
          <option value='' disabled></option>
        </Form.Select>
      </>
    )

  }
}

export default GetCitysByState;