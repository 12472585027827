import React, { useState } from 'react';
import { useTranslation } from "react-i18next";

import i18next from "i18next";

import api from '../../services/api';

import { Container, Row, Col } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { Controller, useForm } from "react-hook-form";
//import Button from 'react-bootstrap/Button';

import { ErrorMessage } from '@hookform/error-message';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import '../../App.css';

import './ProfileForgotPassword.scss';
import ModalDefault from '../../components/Modal/ModalDefault/ModalDefault';
import PageDafault from '../../templates/PageDafault';
import { Helmet } from 'react-helmet';
import monitoringAPI from '../../services/monitoring';

type Request = { 
    email: string,
    language: number,
    source: string
}

function ProfileForgotPassword() {
    const { t } = useTranslation();
    const lng: number = i18next.language === "pt" ? 1 : i18next.language === "en" ? 2 : i18next.language === "es" ? 3 : 1;

    const [, setLoading] = useState<any>(false);
    const validated = false;

    const [sucess, setSucess] = useState<any>("Preencher informações");
    const [log, setLog] = useState<any>(1);

    const [modalContent, setModalContent] = useState<any>();

    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm();


    const onSubmit = (objData: any) => {
        setLoading(true)
        const token = localStorage.getItem('bpasiteAuth') || '{}';
        const config = {
            headers: { 'Authorization': `Bearer ${JSON.parse(token)?.token?.token}` },
        };

        setModalContent('loading');
        const getAuthenticateAccount = async () => {
            let req: Request = { ///LOGIN API
                "email": objData.email,
                "language": lng,
                "source": "site"
            }

            try {
                const { data } = await api.post('/api/LoginSite/ResetPassword', req, config);
                if (data.status !== 400) {
                    setSucess(data.data.texto);
                    setModalContent('sucesso');
                    setLog(data.data.log);
                    if (data.data.log === 1) {
                        setSucess(data.data.texto);
                        setModalContent('error');
                        setLog(data.data.log);
                        monitoringAPI(400, 'LoginSite', 'ResetPassword', data?.data?.texto || 'Retornado status diferente de 200', '', '/api/LoginSite/ResetPassword', req);
                    } else {
                        monitoringAPI(200, 'LoginSite', 'ResetPassword', data?.data?.texto || 'Sucesso', '', '/api/LoginSite/ResetPassword', req);
                        window.location.href = "/";
                    }
                } else {
                    monitoringAPI(400, 'LoginSite', 'ResetPassword', data?.data?.texto || 'Retornado status diferente de 200', '', '/api/LoginSite/ResetPassword', req);
                }
            } catch (error: any) {
                monitoringAPI(400, 'LoginSite', 'ResetPassword', error?.response?.data?.errorMessage || 'Retornado status diferente de 200', '', '/api/LoginSite/ResetPassword', req);
                setModalContent('error');
                setSucess("Preencher informações")
                setLog(1);
                setLoading(false)
                if (error?.response?.status === 400) {
                    setModalContent('error');
                    setSucess("Preencher informações")
                    setLog(1);
                } else if (error?.response?.status === 401) {
                    window.location.href = window.location.origin + '/login';
                }

            }
        }
        getAuthenticateAccount();
    };




    return (
        <>
            <Helmet>
                <html lang={i18next.language} />
                <title>Esqueceu sua Senha? | Parque Bondinho</title>
                <meta name="description" content="Saiba como recuperar sua senha no site do Parque Bondinho com segurança, siga os passos necessários. Acesse nossa página e confira!" />
                <meta name="googlebot" content={process.env.REACT_APP_SERVER_ROBOTS} />

                {/* Essential META Tags */}
                <meta property="og:title" content="Esqueceu sua Senha? | Parque Bondinho" />
                <meta property="og:type" content="TouristAttraction" />
                <meta property="og:image" content={`${process.env.REACT_APP_SERVER_URL}${process.env.REACT_APP_SERVER_LOGO}`} />
                <meta property="og:url" content={process.env.REACT_APP_SERVER_URL} />

                {/* Non-Essential, But Recommended */}
                <meta property="og:description" content="Saiba como recuperar sua senha no site do Parque Bondinho com segurança, siga os passos necessários. Acesse nossa página e confira!" />
                <meta property="og:site_name" content={process.env.REACT_APP_SERVER_NAME} />

                <meta name="robots" content={process.env.REACT_APP_SERVER_ROBOTS} />
                <link rel="canonical" href={process.env.REACT_APP_SERVER_URL} />
            </Helmet>

            <PageDafault>
                <div className='container'>

                    <Container className="profile-container" fluid>
                        <Form noValidate validated={validated} onSubmit={handleSubmit(onSubmit)}>
                            <Row>
                                <div>
                                    <h1 className="display-5 mb-4">{t("profileNew.forgotPassword.title")}</h1>
                                </div>
                            </Row>
                            <Row className="mb-3">

                                <Form.Group as={Col} md="6" className="mb-3" controlId="">
                                    <Form.Label>
                                        {t("profileNew.forgotPassword.email")}
                                    </Form.Label>
                                    <Controller
                                        control={control}
                                        name="email"
                                        rules={{ required: { value: true, message: t("profileNew.forgotPassword.errorEmail") } }}
                                        render={({ field }: any) => (
                                            <Form.Control
                                                {...field}
                                                type="text"
                                                variant="standard"
                                                margin="normal"
                                                required
                                            />
                                        )}
                                    />
                                    <ErrorMessage
                                        errors={errors}
                                        name="email"
                                        render={({ message }) => <div style={{ width: "100%", backgroundColor: "#f8d7da", padding: "0 0.75rem" }}><small style={{ color: "red" }}>{message}</small></div>}
                                    />
                                </Form.Group>
                            </Row>
                            <Row className="d-flex justify-content-end mx-0 mt-5 mb-4">
                                <ModalDefault
                                    name={t("profileNew.forgotPassword.btn")}
                                    header="no"
                                    class="modal-confirm"
                                    btnLeave="yes"
                                    modalContent={modalContent}
                                >
                                    <div className='modal-body text-center'>
                                        {
                                            modalContent !== "loading"
                                                ?
                                                <>
                                                    <div>
                                                        {
                                                            log === 1 || log === "1"
                                                                ?
                                                                <FontAwesomeIcon
                                                                    icon={["fal", "times-circle"]}
                                                                    size="5x"
                                                                    style={{ fontSize: "7.5em", color: "#FF6600" }}
                                                                />
                                                                :
                                                                <FontAwesomeIcon
                                                                    icon={["fal", "check-circle"]}
                                                                    size="5x"
                                                                    style={{ fontSize: "7.5em", color: "#FF6600" }}
                                                                />
                                                        }
                                                    </div>
                                                    <div>
                                                        {sucess}
                                                    </div>
                                                    <div></div>
                                                </>
                                                :
                                                <>
                                                    <div className="text-center">
                                                        <div className="load"></div>
                                                    </div>
                                                    <div>
                                                        <span>{t("profileNew.forgotPassword.loading")}</span>
                                                    </div>
                                                    <div></div>
                                                </>
                                        }
                                    </div>
                                </ModalDefault>
                                {/*                             <Button type="submit" className="form-button w-auto px-4 py-2">
                Resetar
            </Button> */}
                                {/* <Button type="submit" className="form-button w-auto px-4 py-2">{loading === true ? <div className="load small white"></div> : "Resetar"}</Button> */}
                            </Row>
                        </Form>
                    </Container>
                </div>
            </PageDafault>
        </>
    );
}

export default ProfileForgotPassword;