import React, { useEffect, useState, Key } from "react";
import { useTranslation } from "react-i18next";

import { Accordion, Container } from "react-bootstrap";

import './FAQ.scss';
import PageDafault from "../../templates/PageDafault";

function FAQ() {
    const { t } = useTranslation();
    const [more, setMore] = useState<any>([]);
    const [qtd, setQtd] = useState<any>(8);

    function beginAndEnd(start: any, end: any) {
        var aux: any = [];
        for (var i: any = start; i <= end; i++) {
            aux.push(i);
        }
        return aux;
    }

    useEffect(() => {
        setMore(beginAndEnd(1, qtd));
    }, [qtd])

    return (
        <>
            <PageDafault>
                <Container className="download-center py-5">
                    <h1 className="mb-5 text-center display-2">{t("faq.title")}</h1>
                    <div className='bg-white py-4'>
                        {more.map((elem: any, index: Key) => {
                            var aux: any = ("faq.item" + elem);
                            return (
                                <Accordion className="p-3" defaultActiveKey="1">
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>{t(aux)}</Accordion.Header>
                                        <Accordion.Body>
                                            <ul dangerouslySetInnerHTML={{
                                                __html: t("faq.info" + elem),
                                            }}></ul>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            )
                        })
                        }

                        {qtd < 24
                            ?
                            <>
                                <div className="d-flex justify-content-center">

                                    <button className="btn rounded btn-lg text-primary" onClick={() => { if (qtd <= 24) { setQtd(qtd + 8) } }}>Ver mais</button>
                                </div>
                            </>
                            : <></>
                        }
                    </div>
                </Container>
            </PageDafault>
        </>
    )
}

export default FAQ;